@import '../bootstrap_overrides/_variables.scss';
@import '../mixins/_custom-mixins.scss';

//TWO COLUMN CONTENT
.pb-two-column-content {
    .column-image {
        .image-wrapper {
            max-width: 80%;
            float: right;

            @include bpr-media-breakpoint(down, md) {
                float: none;
                max-width: 100%;
            }
        }
    }
    .flex-row-reverse {
        .column-image {
            .image-wrapper {
                float: left;

                @include bpr-media-breakpoint(down, md) {
                    float: none;
                    max-width: 100%;
                }
            }
        }
    }
}